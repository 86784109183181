<template>
  <div class="pageFooter">
    <div class="footerContent">
      <div class="companyName">
        <img src="./img/logoword.png" alt=""  class="companyNameImg">
      </div>
      <div>
        <div class="footerRow">
          <div class="footerLabel">{{$t('footer.headquarter')}}</div>
          <div class="footerValue">{{$t('contactPage.companyAddress')}}</div>
        </div>
        <div class="footerRow">
          <div class="footerLabel">{{$t('contactPage.phone')}}</div>
          <div class="footerValue">{{$t('contactPage.phoneContent')}}</div>
        </div>
        <div class="footerRow">
          <div class="footerLabel">{{$t('contactPage.Email')}}</div>
          <div class="footerValue">wys@zxyelectronics.com</div>
        </div>
      </div>
    </div>
    <div class="copyright">
      Copyright © 2024 {{$t('footer.companyName')}} All Rights Reserved.　{{$t('footer.ICPNo')}}
    </div>
  </div>
</template>

<script>


export default {
  name: "pageFooter",
  components: {},

  data() {
    return {};
  },

  created() {
  },
  computed: {},
  watch: {},
  methods: {},
}
</script>

<style scoped lang="less">
.pageFooter {
  background: #eeeeee;
  color: #7e7e7e;
  display: flex;
  flex-direction: column;
  align-items: center;
  .footerContent {
    display: flex;
    padding: 20px;
    .companyName {
      margin-right: 100px;
      .companyNameImg {
        width: 160px;
      }
    }
    .footerRow {
      display: flex;
      align-items: center;
      font-size: 14px;
      margin: 10px 0;
      .footerLabel {
        color: #7e7e7e;
        margin-right: 4px;
      }
      .footerValue {
        margin-left: 10px;
      }
    }
  }

  .copyright {
    //background: rgba(0,0,0,0.3);
    padding: 16px;
    width: 100%;
    color: #7e7e7e;
  }
}

</style>