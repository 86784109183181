import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import VueSplide from '@splidejs/vue-splide';
import '@splidejs/splide/dist/css/splide.min.css';
import VueI18n from 'vue-i18n'
import {languages} from './i18n/languages'

Vue.use( VueI18n );

let locale = localStorage.getItem('i18n-locale');
const localeList = Object.keys(languages)
if(!localeList.includes(locale)){
  locale = 'zh'
}

const i18n = new VueI18n({
  locale: locale, // set locale
  messages: languages, // set locale messages
})

Vue.use( VueSplide );


Vue.config.productionTip = false

new Vue({
  router,
  store,
  i18n,
  render: h => h(App)
}).$mount('#app')
