import Vue from 'vue'
import VueRouter from 'vue-router'
import HomeView from '../views/HomeView.vue'
import about from '../views/about/about.vue'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    redirect: '/home'
  },
  {
    path: '/home',
    name: 'home',
    component: () => import(/* webpackChunkName: "home" */ '../views/home/home.vue')
  },
  {
    path: '/about',
    name: 'about',
    component: () => import(/* webpackChunkName: "home" */ '../views/about/about.vue')
  },
  {
    path: '/news',
    name: 'news',
    component: () => import(/* webpackChunkName: "news" */ '../views/news/news.vue'),
    children: [
      {
        path: '/newsList',
        name: 'newsList',
        component: () => import(/* webpackChunkName: "news" */ '../views/news/newsList/index.vue'),
      },
      {
        path: '/newsDetail',
        name: 'newsDetail',
        component: () => import(/* webpackChunkName: "news" */ '../views/news/newsDetail/index.vue'),
      }
    ]
  },
  {
    path: '/contact',
    name: 'contact',
    component: () => import(/* webpackChunkName: "join" */ '../views/contact/contact.vue')
  },
  {
    path: '/join',
    name: 'join',
    component: () => import(/* webpackChunkName: "join" */ '../views/join/join.vue')
  },
  {
    path: '/product',
    name: 'product',
    component: () => import(/* webpackChunkName: "product" */ '../views/product/product.vue')
  },
  {
    path: '/application',
    name: 'application',
    component: () => import(/* webpackChunkName: "application" */ '../views/application/index.vue')
  },

  {
    path: '/pdftest',
    name: 'pdftest',
    component: () => import(/* webpackChunkName: "application" */ '../views/pdftest/index.vue')
  }

]

const router = new VueRouter({
  routes
})

export default router


// eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJzdWIiOiIxMjZlY2RiMy1mODljLTQ3YjgtODlkZC1mNDg2MmRiYzJjYTgiLCJ1c2VybmFtZSI6IjE4NTg0NDAxNzQzIiwiaWF0IjoxNzExNzg4ODM0LCJleHAiOjE3MTE3ODg4OTR9.bhofLfqbeJn2OHRDwVUxusvjEeVBx6lOZZDOHS4DGZ8
