<template>
  <div class="about">
    <div class="aboutBanner">
      <banner></banner>
      <div class="companySummery">
        <div class="summeryTitle">{{$t('aboutPage.companySummeryTitle')}}</div>
        <div class="summeryContent">
          <p>
            {{$t('aboutPage.companySummery')}}
          </p>
        </div>
      </div>
      <div class="aboutBannerBox"></div>
    </div>
    <div class="companyProfileWrap">
      <div class="companyProfilePanel">
        <div class="profileTitle">{{$t('aboutPage.companyProfile')}}</div>
        <div class="profileRow">
          <div class="imgBox">
            <img src="./img/company.png" alt="">
          </div>
          <div class="content">
            {{$t('aboutPage.summeryContent')}}
          </div>
        </div>
      </div>
    </div>
    <div class="developmentPathWrap">
      <div class="companyProfilePanel">
        <div class="profileTitle">{{$t('aboutPage.developmentPath')}}</div>
        <div class="profileRow">
          <div class="content">
            {{$t('aboutPage.developmentPathContent')}}
          </div>
          <div class="imgBox">
            <img src="./img/companyDevelop.png" alt="">
          </div>
        </div>
      </div>
    </div>

    <div class="customerWrap">

    </div>
    <page-footer></page-footer>
  </div>
</template>

<script>
import banner from "@/components/banner/banner.vue";
import PageFooter from '@/components/page-footer/page-footer.vue'

export default {
  name: "about",
  components: {'page-footer': PageFooter, banner},

  data() {
    return {};
  },

  created() {
  },
  computed: {},
  watch: {},
  methods: {},
}
</script>

<style scoped lang="less">
.about {
  .aboutBanner {
    height: 28vw;
    overflow: hidden;
    position: relative;
    .aboutBannerBox {
      position: absolute;
      z-index: -1;
      left: 0;
      top: 0;
      right: 0;
      bottom: 0;
      background-image: url("./img/aboutBanner.png");
      background-repeat: no-repeat;
      background-position: center;
      background-size: cover;
      transform: rotate(180deg);
      filter: grayscale(0.2);
    }

    .companySummery {
      color: white;
      text-align: left;
      padding-top: 5vw;
      padding-left: 16vw;

      .summeryTitle {
        font-size: 32px;
      }

      .summeryContent {
        margin-top: 28px;
        width: 50vw;
        font-size: 16px;

        p {
          line-height: 1.8em;
        }
      }
    }
  }

  .companyProfileWrap {
    display: flex;
    justify-content: center;
    padding-top: 20px;

    .companyProfilePanel {
      width: 1080px;
      text-align: left;

      .profileTitle {
        font-size: 20px;
        font-weight: bold;
        margin-bottom: 16px;
      }

      .profileRow {
        display: flex;

        .content {
          line-height: 1.8em;
          font-size: 16px;
          text-indent: 2em;
        }

        .imgBox {
          margin-right: 30px;

          img {
            width: 280px;
          }
        }
      }
    }
  }

  .developmentPathWrap {
    display: flex;
    justify-content: center;
    padding-top: 50px;
    padding-bottom: 50px;

    .companyProfilePanel {
      width: 1080px;
      text-align: left;

      .profileTitle {
        font-size: 20px;
        font-weight: bold;
        margin-bottom: 16px;
      }

      .profileRow {
        display: flex;

        .content {
          line-height: 1.8em;
          font-size: 16px;
          text-indent: 2em;
        }

        .imgBox {
          margin-left: 30px;

          img {
            width: 280px;
          }
        }
      }
    }
  }
}

</style>