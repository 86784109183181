const languages = {
  en: {
    home: 'Home',
    message: {
      hello: 'hello world',
    },
    banner: {
      about: 'About Us',
      product: 'Products',
      application: 'Application',
      news: 'News',
      join: 'Join Us',
      contact: 'Contact Us',
      newsCompany: 'Company',
      newsIndustry: 'Industry',
      domesticBrands: 'Domestic brands',
      foreignBrands: 'Foreign brands',
    },
    aboutPage: {
      companySummeryTitle: 'Nationally renowned electronic components spot supplier',
      companySummery: 'SHENZHEN ZXY Electronics Co., Ltd. originated in 2000 and was officially established in 2008. The company is headquartered in Shenzhen. It is a professional company engaged in analog components and logic devices and is an authorized distributor of many famous companies in the world.',
      companyProfile: 'Company Profile',
      summeryContent: 'SHENZHEN ZXY originated in 2000 and was officially established in 2008. The company is headquartered in Shenzhen. It is a professional company engaged in analog components and logic devices. It is an authorized distributor of many famous companies in the world. The company originated in Hong Kong and is rooted in Shenzhen. With the service concept of reputation first and quality first, it provides customers with flexible and fast services. With the continuous development of the economy, after ten years of hard work, the company has continued to expand in the process of progress. At present, Zhanxinyuan has three sales branches in Beijing, Shenzhen, Shanghai and other places, four storage centers, a technical support center, and an information management (ERP) center. There are more than 30 excellent employees serving more than 10,000 customers in different fields. The company has won the favor and praise of domestic users with its wide variety of spot products, high-quality services and advanced technology.',
      developmentPath: 'Development Path',
      developmentPathContent: 'In 2008, the company established and operated an efficient and real-time ERP system, which has integrated multiple functions such as purchase, sales, storage, logistics, customer management, information exchange, technical forums, etc. Based on the business philosophy of reputation first and technology first, Zhanxinyuan has continuously launched new devices with its unique service methods and the unremitting efforts of all employees. With sufficient stock and continuous growth in turnover, it has formed a complete national sales system for analog devices, becoming a well-known national electronic component stock supplier and a leader in the industry.',
    },
    homePage: {
      companySpirit: 'Reputation First, Quality First',
      servePurpose: 'Best Quality, Lowest Price, Best Service',
      companyType: 'Well-known electronic components spot supplier',
      companySummaryA: 'SHENZHEN ZXY Electronics Co., Ltd. originated in 2000 and was officially established in 2008. The company is headquartered in Shenzhen. It is a professional company engaged in analog components and logic devices and is an authorized distributor of many famous companies in the world.',
      companySummaryB: 'SHENZHEN ZXY originated in Hong Kong and is rooted in Shenzhen. With the service concept of reputation first and quality first, we provide customers with flexible and fast services. With the continuous development of the economy and years of hard work, the company has continued to expand. At present, Zhanxinyuan has three sales branches, four storage centers, a technical support center, and an information management (ERP) center in Beijing, Shenzhen, Shanghai and other places. There are more than 30 excellent employees serving more than 10,000 customers in different fields, including military industry and petroleum testing units. We have good cooperative relations. The company has won the favor and praise of domestic users with its wide variety of spot products, high-quality services and advanced technology.',
      agentBrand: 'Agent Brand',
      agentBrandSubTitle: 'Provide more branding options for each of your designs',
      companyName: 'SHENZHEN ZXY',
    },
    productPage: {
      productTitle: 'main products'
    },
    contactPage: {
      contactUs: 'Contact Us',
      contactUsSubTitle: 'Welcome to contact us, we will serve you wholeheartedly',
      companyName: 'SHENZHEN ZXY Electronics Co., Ltd.',
      phone: 'Tel',
      Email: 'Email',
      address: 'Address',
      fax: 'Fax',
      website: 'URL',
      companyAddress: 'West 3rd and 4th Floor, Building 4, SEG Technology Park, Huafa North Road, Futian District, SHENZHEN',
      phoneContent: '0755-82736869',
    },
    footer: {
      headquarter: 'Headquarter',
      companyName: 'SHENZHEN ZXY',
      ICPNo: 'Beijing ICP No. 05023822-1',
    }
  },
  zh: {
    home: '首页',
    message: {
      hello: '你好',
    },
    banner: {
      about: '关于我们',
      product: '公司产品',
      application: '解决方案',
      news: '新闻资讯',
      join: '加入我们',
      contact: '联系我们',
      newsCompany: '公司动态',
      newsIndustry: '行业资讯',
      domesticBrands: '国内品牌',
      foreignBrands: '国外品牌'
    },
    aboutPage: {
      companySummeryTitle: '全国知名的电子元器件现货供应商',
      companySummery: '深圳市展芯源科技有限公司起源于2000年，正式成立于2008年，公司总部在深圳，是经营模拟元器件、逻辑器件的专业化公司，是世界许多著名公司授权的分销商。',
      companyProfile: '公司简介',
      summeryContent: '展芯源起源于2000年，正式成立于2008年，公司总部在深圳，是经营模拟元器件、逻辑器件的专业化公司，是世界许多著名公司授权的分销商。公司源于香港，扎根于深圳。以信誉第一，质量至上的服务理念为客户提供灵活而快捷的服务。随着经济的不断发展，经过十年的努力，公司在前进中不断扩大，目前，展芯源在北京，深圳，上海等地设有三家销售分公司，四个仓储中心，一个技术支持中心，一个信息管理（ERP）中心，共有30多名卓越员工服务于1万多家不同领域的客户。公司以经营现货品种多、服务优质、技术先进，赢得了国内广大用户的青睐和赞誉。',
      developmentPath: '发展历程',
      developmentPathContent: '公司在2008年建立运行了高效实时的ERP系统，完成了集进，销，存，物流，客户管理，信息交流，技术论坛等多种功能一体化的平台。展芯源本着信誉第一、技术先行的经营之道，以其独特的服务方式，依靠全体员工不懈的努力，新器件不断推出，现货十分充足，经营额持续增长，已形成了完备的模拟器件全国销售体系，成为全国知名的电子元器件现货供应商，在行业中成为佼佼者。',
    },
    homePage: {
      companyName: '展芯源',
      companySpirit: '信誉第一，质量至上',
      servePurpose: '最好的品质，最低的价格，最优的服务',
      companyType: '著名电子元器件现货供应商',
      companySummaryA: '深圳市展芯源科技有限公司起源于2000年，正式成立于2008年，公司总部在深圳，是经营模拟元器件、逻辑器件的专业化公司，是世界许多著名公司授权的分销商。',
      companySummaryB: '展芯源起源于香港，扎根于深圳。以信誉第一，质量至上的服务理念为客户提供灵活而快捷的服务，随着经济的不断发展，经过多年的努力，公司在前进中不断扩大，目前，展芯源在北京、深圳、上海等地设有三家销售分公司，四个仓储中心，一个技术支持中心，一个信息管理(ERP) 中心，共有30多名卓越员工服务于1万多家不同领域的客户，其中军工和石油检测等单位都有着良好的合作关系。公司以经营现货品种多、服务优质、技术先进，赢得了国内广大用户的青睐和赞誉。',
      agentBrand: '代理品牌',
      agentBrandSubTitle: '为您的每一个设计提供更多品牌选择',
    },
    productPage: {
      productTitle: '主要产品'
    },
    contactPage: {
      contactUs: '与我们联系',
      contactUsSubTitle: '欢迎与我们取得联系，我们将竭诚为您服务',
      companyName: '深圳市展芯源科技有限公司',
      phone: '电话',
      Email: '邮箱',
      address: '地址',
      fax: '传真',
      website: '网址',
      companyAddress: '深圳市福田区华发北路赛格科技园四栋西三层、四层',
      phoneContent: '0755-82736869（10线）',
    },
    footer: {
      headquarter: '总部',
      companyName: '展芯源公司',
      ICPNo: '京ICP备05023822-1号',
    }
  }
}

export {
  languages
}