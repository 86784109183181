<template>
  <div class="banner">
    <div class="companyName">
      <img src="./img/logoword.png" alt="" class="companyNameImg">
    </div>
    <div ref="navWrap" class="navWrap">
      <div v-for="(item, index) in navList"
           :key="index"
           :style="navStyle"
           :class="{active:activePath === item.path}"
           class="navItem"
           @click="onNavClick(item)">
        <span class="navName">{{ item.name }}</span>
        <div v-if="Array.isArray(item.children) && item.children.length > 0" class="subNavWrap">
          <div v-for="kk in item.children"
               class="subNavItem"
               :class="{active: kk.subPath === activeSubPath}"
               @click.stop="onSubNavClick(kk)">
            {{ kk.name }}
          </div>
        </div>
      </div>
    </div>
    <div class="langChange">
      <i class="iconfont icon-duoyuyan langChangeIcon"></i>
      <div class="langChangeList">
        <div class="langChangeItem" @click="onLanguageChange('en')">
          <span>English</span>
          <i class="iconfont icon-rightTopArrow toLangIcon"></i>
        </div>
        <div class="langChangeItem" @click="onLanguageChange('zh')">
          <span>中文</span>
          <i class="iconfont icon-rightTopArrow toLangIcon"></i>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "banner",
  components: {},

  data() {
    return {
      navPadWidth: 20,
      activeSubPath: '',
    };
  },

  created() {
  },
  mounted() {
    this.initNavStyle()
    this.initSubPath()
    window.onresize = () => {
      this.$nextTick(() => {
        this.initNavStyle()
      })
    }
  },
  computed: {
    activePath() {
      return this.$route.path
    },
    navStyle() {
      return {
        marginLeft: this.navPadWidth + 'px',
        marginRight: this.navPadWidth + 'px'
      }
    },
    navList(){
      return [
        {
          name: this.$t('home'),
          path: '/home',
        },
        {
          name: this.$t('banner.about'),
          path: '/about',
        },
        {
          name: this.$t('banner.product'),
          path: '/product',
          children: [
            {
              name: this.$t('banner.foreignBrands'),
              path: '/product',
              query: {
                type: 'foreign'
              },
              subPath: 'productForeign', // 只做关键字匹配处理
            },
            {
              name: this.$t('banner.domesticBrands'),
              path: '/product',
              query: {
                type: 'domestic'
              },
              subPath: 'productDomestic', // 只做关键字匹配处理
            },
          ]
        },
        {
          name: this.$t('banner.application'),
          path: '/application',
        },
        {
          name: this.$t('banner.news'),
          path: '/newsList',
          children: [
            {
              name: this.$t('banner.newsCompany'),
              path: '/newsList',
              query: {
                type: 'notify'
              },
              subPath: 'newsListOne', // 只做关键字匹配处理
            },
            {
              name: this.$t('banner.newsIndustry'),
              path: '/newsList',
              query: {
                type: 'news'
              },
              subPath: 'newsListTwo', // 只做关键字匹配处理
            },
          ]
        },
        {
          name: this.$t('banner.join'),
          path: '/join',
        },
        {
          name: this.$t('banner.contact'),
          path: '/contact',
        },
      ]
    }
  },
  watch: {},
  methods: {
    initNavStyle() {
      const navWrap = this.$refs.navWrap
      console.log('navWrap');
      console.log(navWrap);
      if (navWrap) {
        const rect = navWrap.getBoundingClientRect()
        const navWrapWidth = rect.width
        console.log('navWrapWidth');
        console.log(navWrapWidth);
        if (navWrapWidth <= 800) {
          this.navPadWidth = 12
          return
        }
        if (navWrapWidth <= 900) {
          this.navPadWidth = 16
          return
        }
        if (navWrapWidth <= 1200) {
          this.navPadWidth = 24
          return
        }
        if (navWrapWidth <= 1500) {
          this.navPadWidth = 32
          return
        }
        this.navPadWidth = 40
      }
    },

    initSubPath(){
      const query = this.$route.query
      const path = this.$route.path
      if(path === '/product'){
        const type = query.type || 'foreign'
        const tempMap  = {
          foreign: 'productForeign',
          domestic: 'productDomestic'
        }
        this.activeSubPath = tempMap[type] || 'productForeign'
      }
      if(path === '/newsList') {
        const type = query.type || 'notify'
        const tempMap  = {
          notify: 'newsListOne',
          news: 'newsListTwo'
        }
        this.activeSubPath = tempMap[type] || 'newsListOne'
      }
    },


    onNavClick(row) {
      const path = this.$route.path
      if (path !== row.path) {
        let query = {}
        if (Array.isArray(row.children) && row.children[0]) {
          query = row.children[0].query
          this.activeSubPath = row.children[0].subPath
        }

        this.$router.push({
          path: row.path,
          query: query
        })
      }
    },
    onSubNavClick(row) {
      if(this.activeSubPath === row.subPath){
        return
      }
      this.activeSubPath = row.subPath
      this.$router.replace({
        path: row.path,
        query: row.query
      })
    },
    onLanguageChange(locale){
      localStorage.setItem('i18n-locale', locale)
      location.reload()
    }
  },
}
</script>

<style scoped lang="less">
.banner {
  display: flex;
  align-items: center;
  color: white;
  padding-left: 32px;
  padding-right: 32px;

  .companyName {
    .companyNameImg {
      width: 200px;
    }
  }

  .navWrap {
    flex: 1;
    display: flex;
    justify-content: flex-end;
    margin-right: 60px;
    align-items: center;
    font-size: 20px;
    padding: 20px 0;

    .navItem {
      margin: 0 16px;
      cursor: pointer;
      position: relative;

      &.active {
        .navName {
          color: #aa8851;
        }
      }

      &:hover {
        .navName {
          color: #aa8851;
        }

        .subNavWrap {
          display: block;
        }
      }

      .subNavWrap {
        position: absolute;
        top: 36px;
        left: 0;
        width: 160px;
        display: none;
        background: #f5f5f5;
        box-shadow: 0 3px 5px #555;
        color: #2c3e50;
        font-size: 16px;
        text-align: left;

        .subNavItem {
          padding: 8px 10px;

          &.active {
            color: #aa8851;
          }

          &:hover {
            background: #aa8851;
            color: white;
          }
        }

        &:after {
          display: block;
          content: '';
          position: absolute;
          top: -12px;
          left: 0;
          right: 0;
          height: 16px;
        }
      }
    }
  }

  .langChange {
    cursor: pointer;
    position: relative;

    .langChangeIcon {
      font-size: 18px;
    }

    .langChangeList {
      display: none;
      position: absolute;
      top: 24px;
      right: -12px;
      width: 120px;
      background: white;
      color: #333333;
      font-size: 14px;
      padding: 8px;
      text-align: left;
      border-radius: 4px;

      .langChangeItem {
        cursor: pointer;
        padding: 2px 8px;
        margin: 4px 0;
        display: flex;
        align-items: center;
        justify-content: space-between;

        .toLangIcon {
          font-size: 12px;
          color: #666666;
        }
      }

      &:after {
        display: block;
        content: "";
        position: absolute;
        opacity: 0;
        left: 0;
        right: 0;
        top: -10px;
        height: 10px;
      }
    }

    &:hover {
      .langChangeList {
        display: block;
      }
    }
  }
}
</style>